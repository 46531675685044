import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [{
		path: '/',
		name: 'Index',
		component: () => import('../views/Index.vue'),
		redirect: '/home',
		children: [{
				path: "/home",
				name: "Home",
				component: () => import("../views/Home.vue") //首页商品展示页
			}, {
				path: "/goods_list",
				name: "GoodsList",
				component: () => import('../views/GoodsList.vue') //分类排序
			}, {
				path: "/detail/:id",
				name: "GoodsCon",
				component: () => import("../views/GoodsCon.vue") //商品详情
			}, {
				path: "/merchant_settled",
				name: "merchantSettled",
				component: () => import("../views/merchant_settled.vue") //商户入驻
			}, {
				path: "/helpCenter",
				name: "HelpCenter",
				component: () => import("../views/HelpCenter.vue") //帮助中心
			}, {
				path: "/payment",
				name: "Payment",
				component: () => import('../views/order/Payment') //支付
			},
			{
				path: "/confirm",
				name: "Confirm",
				component: () => import('../views/order/Confirm') //分类排序
			},
			{
				path: "/orderCenter", //订单中心
				name: "OrderCenter",
				component: () => import("../views/order/OrderCenter"),
				children: [{
					path: "/order/list", //我的订单
					name: "MyOrder",
					component: () => import("../views/order/MyOrder")
				}, {
					path: "/order/account", //我的账户
					name: "UserAccount",
					component: () => import("../views/order/UserAccount")
				}, {
					path: "/order/addressList", //收货地址
					name: "AddressList",
					component: () => import("../views/order/AddressList")
				}, {
					path: "/order/iphone", //iphone
					name: "iPhone",
					component: () => import("../views/order/iPhone")
				}]
			}, {
				path: "/order/submit/:id", // 提交订单（即点击立即购买跳转的页面）
				name: "OrderSubmission",
				component: () => import("../views/order/OrderSubmission")
			}, {
				path: '/order/cart', //进货单
				name: 'ShoppingCart',
				component: () => import('../views/order/ShoppingCart.vue'),
				meta: {
					requireAuth: true // 需要验证登录状态
				}
			}
		]
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/Login.vue'),
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('../views/Register.vue'),
	}
]

const router = new Router({
	routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
	// to将要访问的路径
	// from 代表从哪个路径跳转而来
	// next 是一个函数,表示放行
	// next() 放行 next('/login') 强制跳转到登录页
	if (to.path == '/login') return next()
	const tokenStr = window.sessionStorage.getItem('token')
	const auth = to.meta.requireAuth; //需要登录验证
	if (auth === true && !tokenStr) return next('/login')
	next()
})
export default router