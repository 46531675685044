<template>
	<div id="app">
		<!-- 路由占位符 -->
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'app'
};
</script>

<style>
body {
	margin: 0;
	background: #f4f4f4;
	font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
#app {
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}
.header_search .el-input__inner {
	border: none;
}
.header_search .el-input-group__append {
	background: linear-gradient(90deg, #f60, #ff3d00);
	border: none;
	border-radius: 0;
	color: #fff;
	cursor: pointer;
}
.searchItem .el-form-item__content {
	margin-left: 0 !important;
}
.searchItem .el-input-group__prepend {
	width: 80px;
}
.company-desc {
	color: #333;
	text-align: center;
	line-height: 36px;
	padding: 20px 0;
	font-size: 14px;
	background: #f4f4f4;
}
.company-desc a {
	color: #f60;
}
a,
a:hover {
	text-decoration: none;
	color: #333;
}
input,
button {
	border: none;
	outline: none;
}
.el-pagination {
	padding: 10px 5px !important;
}
.dot {
	padding: 0 4px;
	height: 20px;
	margin: 8px 10px;
	font-size: 12px;
	line-height: 20px;
	text-align: center;
	background: #fff4e6;
	color: #fd9b34;
}

.kuajing_dot {
	background: #f3eafb;
	color: #8f44e2;
}

.zhiyou_dot {
	background: #eaf3fc;
	color: #4597e4;
}

.wanshui_dot {
	background: #ffeeee;
	color: #fc5b5d;
}
.wrapper {
	margin: 0 auto;
	max-width: 1225px;
}
</style>
