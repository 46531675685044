import Vue from 'vue'
import Vuex from 'vuex'
import {
	getCartNum,
	getArticleCategory,
	getArticleList
} from "@api/user";

Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
	state: {
		count: 0,
		navLsit: []
	},
	getters: {
		getNum(state) {
			// 获取购物车商品数
			return state.count;
		},
		getNavList(state) {
			// 页脚标题
			return state.navLsit;
		}
	},
	mutations: {
		// 获取购物车数量
		getCartNum(state, num) {
			state.count = num
		},
		getNavLsit(state, navLsit) {
			state.navLsit = navLsit
		}
	},
	actions: {
		aGetCartNum(context) {
			return new Promise(resolve => {
				getCartNum().then(res => {
					context.commit('getCartNum', res.data.count)
					resolve(res.data.count);
				});
			})
		},
		// aGetNavLsit(context) {
		// 	return new Promise(resolve => {
		// 		getArticleCategory().then(res => {
		// 			context.commit('getNavLsit', res.data)
		// 			resolve(res.data);
		// 		});
		// 	})
		// }
		articleCategory(context) {
			// let that = this;
			// that.$store.dispatch('aGetNavLsit');
			getArticleCategory().then(async res => {
				res.data.shift();
				context.navLsit = res.data;
				for (let i = 0; i < res.data.length; i++) {
					let cid = res.data[i].id;
					await getArticleList({
						page: 1,
						limit: 20
					}, cid).then(res => {
						Vue.set(context.navLsit[i], 'articleList', res.data);
					});
				}
				// actions.getArticleLists();
				context.commit('getNavLsit', context.navLsit)
				// that.$nextTick(() => {

				// });
			});
		},
		// async getArticleLists() {
		// 	let that = this;
		// 	for (let i = 0; i < that.navLsit.length; i++) {
		// 		that.cid = that.navLsit[i].id;
		// 		await getArticleList(that.q, that.cid).then(res => {
		// 			that.$set(that.navLsit[i], 'articleList', res.data);
		// 		});
		// 	}
		// }
	}
})

export default store
