import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dialog from "./utils/dialog";
import schema from 'async-validator'
import axios from 'axios'
import * as configs from './utils/index'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.prototype.$validator = function(rule) {
  return new schema(rule);
};
Vue.prototype.$config = configs;
Vue.prototype.$dialog = dialog;
Vue.prototype.$http = axios;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
